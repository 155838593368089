body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url("../src/assets/hardwood3.jpeg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
}

.heroText {
  animation: fadeIn ease 5s;
  -webkit-animation: fadeIn ease 5s;
  -moz-animation: fadeIn ease 5s;
  -o-animation: fadeIn ease 5s;
  -ms-animation: fadeIn ease 5s;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.heroSubText {
  animation: 2s slide-right 2s forwards;
  transform: translateX(-150%);
  animation-delay: 2s;
  -webkit-animation: 2s slide-right 2s forwards;
  -webkit-transform: translateX(-150%);
  -webkit-animation-delay: 2s;
  -moz-animation: 2s slide-right 2s forwards;
  -moz-transform: translateX(-150%);
  -moz-animation-delay: 2s;
  -o-animation: 2s slide-right 2s forwards;
  -o-animation-delay: 2s;
  -o-transform: translateX(-150%);
  -ms-animation: 2s slide-right 2s forwards;
  -ms-animation-delay: 2s;
  -ms-transform: translateX(-150%);
}
@-webkit-keyframes slide-right {
  from{
    display:none;
  }
  to {
    display: inherit;
    transform: translateX(0);
  }
}

@keyframes slide-right {
  from{
    display:none;
  }
  to {
    display: inherit;
    transform: translateX(0);
  }
}

@-moz-keyframes slide-right {
  from{
    display:none;
  }
  to {
    display: inherit;
    transform: translateX(0);
  }
}

@-o-keyframes slide-right {
  from{
    display:none;
  }
  to {
    display: inherit;
    transform: translateX(0);
  }
}
@-ms-keyframes slide-right {
  from{
    display:none;
  }
  to {
    display: inherit;
    transform: translateX(0);
  }
}
